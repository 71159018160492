/* TODO: make CSS for individual module */
/* TODO: make common css class for reuse (ex. fixed button on bottom, heading color, description color, primary color, secondary color, highlight color) */
/* TODO: no same class and same css should be on different file */
html {
  height: 100vh;
  /* background: #eee; */
}
#root {
  height: 100%;
  position: relative;
}
img {
  max-width: 100%;
}
body {
  margin: 0;
  height: 100vh;
  color: #444;
  /* font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Segoe UI",
    sans-serif; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333 !important;
}
.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}

code {
  font-family: "Roboto";
}
button {
  /* width: '35%';  */
  border: none;
}
.am-navbar {
  background-color: #aeca36 !important;
  /* position: fixed;
    width: 100%;
    z-index: 2;
    margin-bottom: 45px; */
}
.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* margin-top: 45px !important; */
}
.my-drawer .am-drawer-sidebar {
  z-index:6!important;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}
.am-card-header-content,
.am-list-item .am-list-line .am-list-content{
  color: #444 !important;
}
.active.am-list-item .am-list-line .am-list-content,
.active.am-list-item .am-list-thumb svg path{
  color: #aeca36 !important;
  fill: #aeca36 !important;
}

label {
  display: block;
  background-color: #333333;
  width: 100% !important;
  color: #aaa;
  font-size: 1.05em;
}

.am-image-picker-item {
  border-radius: 50% !important;
}

.pay-status {
  margin-top: 15vh;
  font-size: 2em;
  /* padding: 0.5em !important; */
  display: flex;
  align-items: center;
  margin: 10px 0 ;
}
.pay-status > svg {
  padding-top: 4px !important;
  color: #333333;
  width: 55px !important;
  height: 50px !important;
}
.how-it-works{
  cursor: pointer;
}
.btn {
  background-color: #aeca36 !important;
  color: white !important;
  min-height: 55px !important ;
  line-height: 55px !important;
  border: none !important;
  padding: 0 10px !important;
}

.btn-inverted {
  background-color: white !important;
  color: #aeca36 !important;
  min-height: 55px !important ;
  line-height: 55px !important;
  border: none !important;
  padding: 0 10px !important;
}
.btn,
.btn-inverted {
  font-weight: bold;
  font-size: 17px !important;
  letter-spacing: 0.0357143em;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 160px;
}
.btn-disabled{
  display:flex!important;
  justify-content: center;
  align-items: center;
}
.am-button {
  border: none !important;
}
.am-button::before {
  content: none !important;
}
.am-list-item .am-input-label {
  margin: 0 0 2px 10px !important;
}
.am-modal-button:last-child {
  color: #aeca36 !important;
}
.loading-example {
  display: flex;
  justify-content: flex-start;
}
.align {
  display: flex;
  flex-direction: column;
}
.am-drawer-content {
  position: relative;
  min-height: calc(100vh - 67px);
}
.am-drawer-left.am-drawer-open .am-drawer-overlay {
  position: absolute;
  /* margin-top: 45px; */
}
.slider-slide > img {
  object-fit: cover;
}

.sider-list .am-list-body .am-list-item {
  min-height: 50px !important;
}
.am-drawer.am-drawer-left .am-drawer-draghandle,
.am-drawer.am-drawer-right .am-drawer-draghandle {
  width: 0 !important;
}

.vertical-center {
  color: rgb(204, 204, 204);
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -50px;
}

.am-modal-button-group-h.am-modal-button-group-normal .am-modal-button{
  font-size: 16px;
  text-transform: uppercase;
}
.am-modal-alert-content, .am-modal-propmt-content{
  padding: 15px;
}
.align-right{
  text-align: right;
}
.align-left{
  text-align: left;
}
.btn-floating {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
}
.btn-floating > .btn {
  width: 100%;
  border-radius: 0;
}
.img-div{
  min-width: 50px;
  margin: 5px;
}
.contact-link{
  color: #aeca36;
}
.description-para{
  letter-spacing:0.02em ;
  line-height: 1.3em;
}
@media (min-width: 768px) {
  html{
    max-width: 70%;
    margin: 0 auto;
  }
  .btn-floating{
    max-width: 70%;
  }
  .my-drawer {
    /* max-width: 70%; */
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .my-drawer {
    margin: 0 auto;
  }
  .btn-floating{
    max-width: 35%;
  }
  html {
    max-width: 35%;
    margin: 0 auto;
  }
}
.refer-code.main-div{
  flex-direction: column;
}
.refer-code.code{
  font-size: 2.5em;
  color:#aeca36;
  margin:15px 0px 3px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refer-code-block{
  border:2px dotted #aeca36;
  min-width: 225px;
  border-radius: 15px;
}
.refer-code.text{
  margin:0 0px 15px 0px;
  font-size:0.85em;
}
.refer-description{
  line-height: 1.25em;
  font-size: 1.05em;
  padding:0 15%;
  text-align:center;
}
.refer-description b{
  color:#aeca36;
}
.share-btn svg{
  margin:0 0 0 5px;
  position: relative;
  top: 5px;
}
.m-5{
  margin:5px;
}
.text-center{
  text-align: center;
}